import { getFilterConfigs } from '../../hooks/useFilterConfigs';
import type { APIData } from '../../types/APIData';
import type { FilterValue } from '../../types/Filter';
import type { Tab } from '../../types/Tab';
import { WidgetId } from '../../types/Widget';
import type { Widget } from '../../types/Widget';
import type ApiFeaturesValues from '../features/types/ApiFeaturesValues';
import WidgetFeaturesIds from '../features/types/WidgetFeaturesIds';
import getImageUrl from '../getImageUrl';
import type Localiser from '../Localiser';

import getFilterValues from './getFilterValues';
import getWhitelist from './getWhitelist';

export interface GetTabConfigsProps {
  data: APIData | Record<string, never>;
  params: Partial<ApiFeaturesValues> | Record<string, never>;
  features: Partial<WidgetFeaturesIds>;
  area: string | null;
  widget: Widget;
  localiser: Localiser;
  models: Record<string, number>;
}

export default ({
  data,
  params,
  features,
  area,
  widget,
  localiser,
  models,
}: GetTabConfigsProps): Tab[] => {
  const { counts = {}, filters = [] } = data || {};
  const { tabs, showBundleOffers, link, linkText } = features;

  const labelFilter = filters.find((f) => {
    if (tabs === 'priority') {
      const islabelType = f.category === 'label';
      const hasValuesWithTabActive = f?.values?.find((v) => v.tab_active);
      return islabelType && hasValuesWithTabActive;
    }
    if (widget.id === 'review' && f.filter_key === 'filter_label[text_game_platform]') {
      return f;
    }
    return false;
  });

  if (tabs === 'requestedModel') {
    const popularModelFilter = filters.find((f) => f.type === 'popular_model');

    if (popularModelFilter) {
      return (popularModelFilter.values || []).map((v) => {
        return {
          value: v.formatted_value,
          label: v.formatted_value,
          countProperty: data.offer_type,
          params: {
            model_name: v.formatted_value,
          },
          category: 'model',
          pageSize: params.rows || 1,
        };
      });
    }

    return Object.keys(models).map((modelName) => {
      return {
        value: modelName,
        label: modelName,
        countProperty: data.offer_type,
        params: {
          model_name: modelName,
        },
        category: 'model',
        pageSize: params.rows || 1,
      };
    });
  }

  if (widget.id === 'nighthawk') {
    const specs = getFilterConfigs(filters, 'label');

    if (specs.length > 0) {
      return [
        {
          value: 'deals',
          label: localiser.translate('specifications'),
          countProperty: 'deals',
          params: {
            filter_product_types: 'deals,similar',
          },
          category: 'retail',
          pageSize: params.rows || 4,
        },
      ];
    }

    return [];
  }

  /* The order of the tabs need to match the product types priority.
  Otherwise we would send a request, get 1 type of deals and setup a tab
  for another and therefore show nothing */
  if (counts.broadband > 0) {
    return [
      {
        value: 'broadband',
        label: localiser.translate('comparisonBroadbandTabLabel'),
        countProperty: 'broadband',
        params: {
          filter_product_types: 'broadband',
        },
        category: 'broadband',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.multimedia > 0) {
    return [
      {
        value: 'multimedia',
        label: localiser.translate('multimediaTabLabel'),
        countProperty: 'multimedia',
        params: {
          filter_product_types: 'multimedia',
        },
        category: 'multimedia',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.subscriptions > 0) {
    return [
      {
        value: 'subscriptions',
        label: localiser.translate('reviewSubscriptionsTabLabel'),
        countProperty: 'subscriptions',
        params: {
          filter_product_types: 'subscriptions',
        },
        category: 'subscriptions',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.contracts > 0) {
    const tabConfigs: Tab[] = [];
    const networkFilter = filters.find(
      (f) => tabs === 'network' && f.filter_key === 'filter_label[text_network]',
    );

    if (networkFilter) {
      return getFilterValues(networkFilter, params)
        .filter((v) => v.value !== '')
        .map((value) => {
          return {
            value: String(value.value).toLowerCase().replace(/ /g, '-'),
            label: localiser.translate(value.formatted_value),
            image: value.image_url,
            countProperty: 'contracts',
            params: {
              [networkFilter.filter_key]: value.value,
            },
            category: 'contracts',
            pageSize: params.rows || 3,
          };
        });
    }

    tabConfigs.push({
      value: 'contracts',
      label: ((): string => {
        switch (widget.id) {
          case WidgetId.COMPARISON:
            return localiser.translate('comparisonContractsTabLabel');
          default:
            return localiser.translate('reviewContractsTabLabel');
        }
      })(),
      countProperty: 'contracts',
      params: {
        filter_product_types: 'contracts',
      },
      category: 'contracts',
      pageSize: params.rows || 3,
    });

    if (counts.deals > 0 || counts.similar > 0) {
      tabConfigs.push({
        value: 'deals',
        label: localiser.translate('reviewHandsetsTabLabel'),
        countProperty: 'deals',
        params: {
          filter_product_types: 'deals',
        },
        category: 'retail',
        pageSize: params.rows || 4,
      });
    }

    // Add Sim Only tab for the UK only
    if (tabs === 'simOnly' && counts.contracts > 0 && area === 'GB') {
      tabConfigs.push({
        value: 'simOnly',
        label: localiser.translate('comparisonSimOnlyTabLabel'),
        countProperty: 'contracts',
        params: {
          model_name: 'Sim Only',
          filter_product_types: 'contracts',
        },
        category: 'contracts',
        pageSize: params.rows || 3,
      });
    }

    return tabConfigs;
  }
  if (counts.deals > 0 || counts.similar > 0) {
    if (labelFilter) {
      const getTabConfigForValue = (value: FilterValue): Tab | null => {
        // Get the model label value and check for an exact match
        // or a float match to handle storage sizes (e.g. 500.0 v 500)
        const modelLabelValue = (labelFilter.values || []).find((v) => {
          return (
            v.tab_active &&
            (v.value === value.value ||
              parseFloat(String(v.value)) === parseFloat(String(value.value)))
          );
        });

        // Setup a tab config for all tabs except the any tab
        // Format the value as we might use this as the css class name
        if (modelLabelValue) {
          return {
            value: String(value.value).toLowerCase().replace(/ /g, '-'),
            rawValue: String(value.value),
            label: localiser.translate(value.formatted_value),
            image: modelLabelValue.image_url,
            mobileImage: modelLabelValue.mobile_image_url,
            countProperty: 'deals',
            params: {
              [labelFilter.filter_key]: value.value,
            },
            category: 'retail',
            type: labelFilter.type,
            pageSize: params.rows || 4,
          };
        }

        return null;
      };

      const tabConfigs = getFilterValues(labelFilter, params).reduce(
        (result: Tab[], value: FilterValue) => {
          const tabConfig = getTabConfigForValue(value);

          if (tabConfig) {
            return result.concat([tabConfig]);
          }
          return result;
        },
        [],
      );
      const whitelist = getWhitelist(labelFilter, params);
      const tabConfigValues = tabConfigs.map((t) => t.params[labelFilter.filter_key]);
      const otherLabelValues = (labelFilter.values || []).filter(
        (v) => tabConfigValues.indexOf(v.value) === -1,
      );

      // Show the other tab if there are labels which don't have their own tab
      if (otherLabelValues.length > 0) {
        tabConfigs.push({
          value: 'other',
          rawValue: 'null',
          label: localiser.translate('reviewOtherPlatformTabLabel'),
          /* Setup other tab, but only set an image if there is another tab which has an
          image or this is th only tab (specifically filtered for) */
          image:
            tabConfigs.find((t) => t.image) || tabConfigs.length === 0
              ? getImageUrl('misc/three_dots.png')
              : null,
          countProperty: 'deals',
          params: {
            [labelFilter.filter_key.replace('filter_label', 'filter_label_blacklist')]:
              tabConfigValues.join(','),
          },
          category: 'retail',
          type: labelFilter.type,
          pageSize: params.rows || 4,
        });
      }

      if (tabConfigs.length > 0) {
        // If there is a whitelist, ensure the tab configs are in the order of the whitelist
        // this is to handle the other tab being specified
        if (whitelist.length > 0) {
          return whitelist.reduce((result: Tab[], value) => {
            const tab = tabConfigs.find(
              (t: Tab) => t.rawValue && t.rawValue.toLowerCase() === value.toLowerCase(),
            );
            if (tab) {
              return result.concat(tab);
            }
            return result;
          }, []);
        }

        return tabConfigs;
      }
    }

    const tabsConfigs: Tab[] = [
      {
        value: 'deals',
        label: localiser.translate('reviewRetailTabLabel'),
        countProperty: 'deals',
        params: {
          filter_product_types: 'deals,similar',
        },
        category: 'retail',
        pageSize: params.rows || 4,
      },
    ];

    // Add a non bundle tab for the bundle widget
    if (showBundleOffers) {
      tabsConfigs.push({
        value: 'bundle',
        label: localiser.translate('bundleTabLabel'),
        countProperty: 'deals',
        params: {
          filter_product_types: 'deals',
          bundle_models: 1,
        },
        category: 'retail',
        pageSize: params.rows || 4,
      });
    }

    return tabsConfigs;
  }
  if (counts.offers > 0) {
    return [
      {
        value: 'offers',
        label: localiser.translate('offersTabLabel'),
        countProperty: 'offers',
        params: {
          filter_product_types: 'offers',
        },
        category: 'offers',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.vouchers > 0) {
    return [
      {
        value: 'vouchers',
        label: localiser.translate('offersVouchersTabLabel'),
        countProperty: 'vouchers',
        params: {
          filter_product_types: 'vouchers',
        },
        category: 'vouchers',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.offer_deals > 0) {
    return [
      {
        value: 'offer_deals',
        label: localiser.translate('offersDealsTabLabel'),
        countProperty: 'offer_deals',
        params: {
          filter_product_types: 'offer_deals',
        },
        category: 'offer_deals',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.fallback > 0 || (link && linkText)) {
    return [
      {
        value: 'fallback',
        label: localiser.translate('fallbackLabel'),
        countProperty: 'fallback',
        params: {
          filter_product_types: 'fallback',
        },
        category: 'retail',
        pageSize: params.rows || 4,
      },
    ];
  }
  if (counts.unique_codes > 0) {
    return [
      {
        value: 'unique_codes',
        label: localiser.translate('offersUniqueCodesTabLabel'),
        countProperty: 'unique_codes',
        params: {
          filter_product_types: 'unique_codes',
        },
        category: 'unique_codes',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.newsletter > 0) {
    return [
      {
        value: 'newsletter',
        label: localiser.translate('newsletterTabLabel'),
        countProperty: 'newsletter',
        params: {
          filter_product_types: 'newsletter',
        },
        category: 'newsletter',
        pageSize: params.rows || 3,
      },
    ];
  }
  if (counts.rewards > 0) {
    return [
      {
        value: 'rewards',
        label: localiser.translate('rewardsTabLabel'),
        countProperty: 'rewards',
        params: {
          filter_product_types: 'rewards',
        },
        category: 'rewards',
        pageSize: params.rows || 3,
      },
    ];
  }

  return [];
};
