import getBundleTabData from './getBundleTabData';
import getTabScopedProps from './getTabScopedProps';
import type { ModelWidgetData } from './types';

export const setupTabBasedData = async (
  setupModelWidgetData: ModelWidgetData,
): Promise<ModelWidgetData> => {
  const { options, model } = setupModelWidgetData;
  const { apiFeatures, widgetFeatures, props, features } = options;
  const { getJSON, widgetEndpoint, widget } = props;
  const { response, defaultTab, reFetchData, widgetMissing, responseTime, tabConfigs } = model;
  const { data } = response;

  if (widgetMissing) {
    return setupModelWidgetData;
  }

  // Check if we should send additional requests & setup the tab based data & params
  const tabScopedProps = await getTabScopedProps({
    defaultTab,
    tabConfigs,
    data,
    params: props.params,
    showFilters: features.showFilters ?? false,
    reFetchData,
    getJSON,
    widgetEndpoint,
    widget,
  });
  const bundle = getBundleTabData(tabScopedProps.tabData, tabConfigs, defaultTab);

  // Set the tabs for the features so we can conditionally set a feature based on
  // the product type (e.g. showPrice)
  widgetFeatures.setAttribute('defaultTab', defaultTab);
  apiFeatures.setAttribute('defaultTab', defaultTab);
  const newFeatures = widgetFeatures.getFeatures('id');
  return {
    options: {
      ...options,
      props: {
        ...props,
        ...newFeatures,
        params: apiFeatures.getFeatures('value', true),
      },
      widgetFeatures,
      apiFeatures,
      features: newFeatures,
    },
    model: {
      ...model,
      tabData: bundle.data,
      tabConfigs: bundle.tabConfigs,
      responseTime: responseTime + tabScopedProps.tabResponseTime,
      tabParams: tabScopedProps.tabParams,
    },
  };
};
