import type { WidgetRequestParameters } from '../../../assets/types/Request';
import type { APIData } from '../../types/APIData';
import type { RequestType, getJSONFn } from '../../types/GetJSON';
import type { Tab } from '../../types/Tab';
import type { TabData } from '../../types/TabData';
import { WidgetId } from '../../types/Widget';
import type { Widget } from '../../types/Widget';
import type { WidgetProps } from '../../types/WidgetProps';

import fetchModelData from './fetchModelData';
import shouldGetDataForTab from './shouldGetDataForTab';

interface Options {
  defaultTab: Tab | null;
  tabConfigs: Tab[];
  data: APIData | Record<string, never>;
  params: WidgetProps['params'];
  showFilters: boolean;
  reFetchData: boolean;
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>;
  widgetEndpoint: string;
  widget: Widget;
}

interface TabScopedProps {
  tabData: TabData;
  tabParams: WidgetRequestParameters;
  tabResponseTime: number;
}

export default async (options: Options): Promise<TabScopedProps> => {
  const {
    defaultTab,
    tabConfigs,
    data,
    params,
    showFilters,
    reFetchData,
    getJSON,
    widgetEndpoint,
    widget,
  } = options;
  /**
   * Get data for all tabs if:
   * - The bundle models feature is enabled
   * - We are using model tabs
   * - It's a specs widget
   */
  const getDataForAllTabs =
    Boolean(params.bundle_models) ||
    (defaultTab && defaultTab.category === 'model') ||
    widget.id === WidgetId.SPECS;

  const tabData = {};
  const tabParams = {} as WidgetRequestParameters;
  let tabResponseTime = 0;
  // E.g. we'll need to if the widget type was changed by battle or the widget type component
  for (const [index, tab] of tabConfigs.entries()) {
    const newParams = {
      ...params,
      ...(tab.params || {}),
    };

    /**
     * Setup data & params for the tab - we need to setup the params in all cases
     * to ensure they are available when the tab is changed
     * - If it's the default tab, use the provided data & params unless
     * we should re fetch the datawid
     * - If the tab should fetch data, update the data if the request
     * succeeds and the params either way
     * - If the tab isn't the default & shouldn't fetch data, ensure the params are setup
     */
    const shouldGetData = shouldGetDataForTab({
      tab,
      tabConfigs,
      defaultTab,
      getDataForAllTabs,
      showFilters,
      index,
      reFetchData,
    });
    // Setup a default data "type" so we don't have missing properties and errors when changing tabs
    const defaultData = {
      model_info: {},
      offers: [],
      filters: [],
    };
    if (defaultTab && tab.value === defaultTab.value && !shouldGetData) {
      tabData[tab.value] = data;
      tabParams[tab.value] = params;
    } else if (shouldGetData) {
      try {
        const { data: resultData, responseTime } = await fetchModelData(
          getJSON,
          widgetEndpoint,
          newParams,
        );
        tabData[tab.value] = resultData;
        tabParams[tab.value] = newParams;
        tabResponseTime += responseTime;
      } catch (e) {
        tabData[tab.value] = defaultData;
        tabParams[tab.value] = newParams;
      }
    } else {
      tabData[tab.value] = defaultData;
      tabParams[tab.value] = newParams;
    }
  }

  return {
    tabData,
    tabParams,
    tabResponseTime,
  };
};
