import type { InitialiserResult } from '../../types/InitialiserResult';
import type { TabData } from '../../types/TabData';
import type { WidgetProps } from '../../types/WidgetProps';

import fetchSeasonalData from './fetchSeasonalData';

const getType = (apiCall: string, data, keywords: string[] | null): string => {
  const seasonalTags = data?.tags || [];
  if (apiCall !== 'success' || !data) {
    return 'missing';
  }
  if (seasonalTags?.length > 0 && keywords) {
    const seasonalKeyword = seasonalTags.find((tag) => {
      return keywords.includes(tag);
    });
    return seasonalKeyword ? 'success' : 'skipped';
  }
  return 'success';
};

export default async ({ props }: { props: WidgetProps }): Promise<InitialiserResult> => {
  const { getJSON, widgetEndpoint, params, keywords } = props;

  try {
    const result = await fetchSeasonalData(getJSON, widgetEndpoint, params);

    return {
      props: {
        ...props,
        data: (result.data as unknown as TabData) ?? {}, // TODO: remove retype
      },
      battle: null,
      responseTime: result.responseTime,
      apiCall: 'success',
      type: getType('success', result.data, keywords),
    };
  } catch (e) {
    return {
      props: {
        ...props,
        data: {},
      },
      battle: null,
      responseTime: 0,
      apiCall: 'failure',
      type: getType('failure', null, keywords),
    };
  }
};
