import type { Deal, OverrideDeal } from '../../../types/Deal';

import { getDealOverrides } from './getDealOverrides';

export const getOverridedDeal = (overrides: Partial<OverrideDeal>, deal: Deal): Deal => {
  return Object.keys(overrides).reduce((dealAcc, key) => {
    // Ensure that non specified fields are preserved - i.e. we don't replace the entire field
    if (Array.isArray(overrides[key])) {
      return {
        ...dealAcc,
        [key]: (deal[key] || []).concat(overrides[key]),
      };
    }
    if (typeof overrides[key] === 'object') {
      return {
        ...dealAcc,
        [key]: {
          ...dealAcc[key],
          ...getDealOverrides(dealAcc, overrides[key]),
        },
      };
    }
    return {
      ...dealAcc,
      [key]: overrides[key],
    };
  }, deal);
};
