export const storeWidgetData = (data: Record<string, unknown>, key: string): void => {
  // Try catch to check local storage is available
  // Local storage is not available in safari incognito
  try {
    if (typeof window !== 'undefined') {
      // Store the API response in local storage
      window.localStorage[`hawk-${key}`] = JSON.stringify(data);
    }
  } catch (e) {
    // Local storage is not available;
    // Don't throw an error as this will prevent the code from continuing
    // e.g. Safari Private blocks saving to local storage
  }
};
