/**
 * Return the url with the site, territory and custom tracking id
 * Exception: don't include the territory
 * if the site is "hawk" to ensure we use the simple placeholder
 * This is for manual products in the console and indexed data,
 * which will be rewritten by widgets/hawklinks
 */
export const getRewrittenUrl = (
  url: string,
  site: string,
  territory: string,
  customTrackingId?: string | number | null,
  articleUrl?: string,
  articleName?: string,
): string => {
  articleUrl = articleUrl || 'hawk-article-url';
  articleName = articleName || 'hawk-article-name';

  // Use '_' for SL custom tracking
  site = (site || 'hawk').toLowerCase();
  territory = (territory || 'zz').toLowerCase();
  const separator =
    (url || '').indexOf('go.redirectingat.com') === -1 || customTrackingId === 'custom-tracking'
      ? '-'
      : '_';
  const prefix = site === 'hawk' ? site : `${site}${separator}${territory}`;
  customTrackingId = customTrackingId || 'custom-tracking';

  url = (url || '')
    .replace('hawk-article-url', encodeURIComponent(articleUrl))
    .replace('hawk-article-name', encodeURIComponent(articleName))
    .replace('hawk-prefix', prefix);

  return url.replace(/hawk-custom-tracking/g, `${prefix}${separator}${customTrackingId}`);
};
