import { shouldSendAPIRequest } from '../features/shouldSendAPIRequest';

import fetchModelData from './fetchModelData';
import type { ModelWidgetData } from './types';
import { changeToNighthawk } from './utils/changeToNighthawk';

export const sendApiRequest = async (
  setupModelWidgetData: ModelWidgetData,
): Promise<ModelWidgetData> => {
  const { options, model } = setupModelWidgetData;
  const { props: defaultProps } = options;
  const { getJSON, widgetEndpoint } = defaultProps;

  if (!shouldSendAPIRequest(defaultProps.widget, defaultProps.params)) {
    return {
      options,
      model: {
        ...model,
        status: 'skipped',
      },
    };
  }

  try {
    /* If the widget has changed to a nighthawk widget then we must update props
      and params before getting the data from the API in order to ensure we return
      all filters so that we can correctly setup the tab configs */
    const { props, widgetFeatures, apiFeatures, features } = changeToNighthawk(options);
    const { params } = props;

    const result = await fetchModelData(getJSON, widgetEndpoint, params);

    return {
      options: {
        ...options,
        apiFeatures,
        widgetFeatures,
        features,
        props,
      },
      model: {
        ...model,
        status: 'success',
        responseTime: result.responseTime,
        response: result,
      },
    };
  } catch (e) {
    return {
      options,
      model: {
        ...model,
        status: 'failure',
      },
    };
  }
};
