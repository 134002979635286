import type { Promo } from '../../../../types/Deal';

import { decodeCustomPromos } from './decodeCustomPromos';

export const addCustomPromos = ({ customPromo, customPromos, promos, localiser }): Promo[] => {
  if (customPromos) {
    const customPromosArray = decodeCustomPromos(customPromos);
    const arrayOfPromos = customPromosArray.map((element) => {
      return {
        type: 'custom-promo',
        value: localiser.translate(element),
        display_value: localiser.translate(element),
      };
    });

    return (promos || []).concat(arrayOfPromos);
  }
  if (customPromo) {
    return (promos || []).concat([
      {
        type: 'custom-promo',
        value: localiser.translate(customPromo),
        display_value: localiser.translate(customPromo),
      },
    ]);
  }
  return promos;
};
