import type { Tab } from '../../types/Tab';

interface ShouldGetDataForTabProps {
  tab: Tab;
  tabConfigs: Tab[];
  defaultTab: Tab | null;
  getDataForAllTabs: boolean;
  showFilters: boolean;
  index: number;
  reFetchData: boolean;
}

/**
 *  * Get data for the tab if it:
 * - is the first tab and contains an API filter that isn't for product
 * types (e.g. game platform label), or we need to re-fetch the data
 * - if there are multiple tabs & we are showing the filters, we should
 * update the data for the initial tab to ensure the counts are accurate
 * - is the nonBundle tab. This is so we can merge the data with the default tab later
 */
export default ({
  tab,
  tabConfigs,
  defaultTab,
  getDataForAllTabs,
  showFilters,
  index,
  reFetchData,
}: ShouldGetDataForTabProps): boolean => {
  if (defaultTab && tab.value === defaultTab.value) {
    if (
      tab.params &&
      Object.keys(tab.params).filter(
        (k) => k.indexOf('filter_') >= 0 && k !== 'filter_product_types',
      ).length > 0
    ) {
      return true;
    }
    if (reFetchData) {
      return true;
    }
    if (showFilters && tabConfigs.length > 1) {
      return true;
    }
    return false;
  }
  if (getDataForAllTabs && index !== 0) {
    return true;
  }
  if (tab.value === 'bundle') {
    return true;
  }
  return false;
};
