import getWidgetsEndpoint from './getWidgetsEndpoint';

export const loadCSS = (component: string): void => {
  if (typeof window !== 'undefined' && !document.getElementById(`hawkStyles-${component}`)) {
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.id = `hawkStyles-${component}`;
    linkTag.href = `${getWidgetsEndpoint()}/css/browser/${
      process.env.VERSION
    }/${component}.min.css`;
    document.head.appendChild(linkTag);
  }
};
