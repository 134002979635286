import type { Promo } from '../../../../types/Deal';
import { WidgetId } from '../../../../types/Widget';

export const getFilteredPromos = ({
  excludeFrom,
  localiser,
  widget,
  promos,
  showPromos,
}): Promo[] => {
  // if excludeFrom is set to all in the CMS set promos to REPORTED OUT OF STOCK
  if (excludeFrom) {
    return [
      {
        value: localiser.translate('reportedOutOfStockPromo'),
        display_value: localiser.translate('reportedOutOfStockPromo'),
        type: '',
      },
    ];
  }

  // Remove bundle promos for the bundle widget - this avoids showing them in 2 places
  if (widget && widget.id === WidgetId.BUNDLE && promos) {
    return promos.filter((promo) => promo.type !== 'bundle');
  }

  // Remove the promos if the showPromos feature is disabled
  if (!showPromos) {
    return [];
  }

  return promos;
};
