import { WidgetId } from '../../types/Widget';
import type { Widget } from '../../types/Widget';
import type { WidgetInitialiser } from '../../types/WidgetInitialiser';
import setupModelWidget from '../setupModelWidget';
import setupSeasonalWidget from '../setupSeasonalWidget';

export default async (widget: Widget): Promise<WidgetInitialiser> => {
  switch (widget.id) {
    case WidgetId.SEASONAL:
      return setupSeasonalWidget;
    default:
      return setupModelWidget;
  }
};
