import type { Filter } from '../../types/Filter';

export default (filters: Filter[], type: string): Filter[] => {
  switch (type) {
    case 'brand':
      return filters.filter((filter) => filter.filter_key === 'filter_label[text_brand]');
    case 'label':
      return filters.filter(
        (filter) =>
          filter.category === 'label' &&
          !['filter_label[text_network]', 'filter_label[text_brand]'].includes(filter.filter_key),
      );
    case 'monthlyCost':
      return filters.filter((filter) => filter.filter_key === 'filter_monthly_cost');
    case 'upfrontCost':
      return filters.filter(
        (filter) => filter.filter_key === 'filter_upfront' && filter.category === 'contract_price',
      );
    case 'retailCost':
      return filters.filter(
        (filter) => filter.filter_key === 'filter_upfront' && filter.category === 'price',
      );
    case 'cost':
      return filters.filter((filter) => filter.category === 'price');
    case 'contractCost':
      return filters.filter((filter) => filter.category === 'contract_price');
    case 'merchant':
      return filters.filter((filter) => filter.filter_key === 'filter_merchants');
    case 'basicContract':
      return filters.filter((filter) => {
        return (
          filter.category === 'contract' &&
          filter.filter_key !== 'filter_upgrade' &&
          filter.filter_key !== 'filter_contract_length' &&
          filter.filter_key !== 'filter_data'
        );
      });
    case 'data':
      return filters.filter(
        (filter) => filter.category === 'contract' && filter.filter_key === 'filter_data',
      );
    case 'model':
      return filters.filter(
        (filter) => filter.filter_key === 'model_name' && filter.category === 'model',
      );
    case 'category':
      return filters.filter(
        (filter) => filter.filter_key === 'model_name' && filter.category === 'category',
      );
    case 'contractLength':
      return filters.filter((filter) => filter.filter_key === 'filter_contract_length');
    case 'network':
      return filters.filter((filter) => filter.filter_key === 'filter_label[text_network]');
    case 'upgrade':
      return filters.filter((filter) => filter.filter_key === 'filter_upgrade');
    case 'package':
      return filters.filter((filter) => filter.filter_key === 'filter_includes');
    case 'speed':
      return filters.filter((filter) => filter.filter_key === 'filter_speed');
    case 'type':
      return filters.filter((filter) => filter.filter_key === 'filter_type');
    case 'colour':
      return filters.filter((filter) => filter.filter_key === 'filter_label[text_colour]');
    default:
      return [];
  }
};
