import React from 'react';

import { IRenderWidgetProps } from '../../../assets/modules/renderWidget/model';
import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { getInitialiser } from '../../modules/getInitialiser';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../types/GetJSON';
import type { Translate } from '../../types/Localiser';
import { WidgetInitialiserOptions } from '../../types/WidgetInitialiser';
import type { WidgetProps } from '../../types/WidgetProps';

import PromotionMain from './PromotionMain';
import styles from './styles/promotion-fallback.css';

loadCSS('promotionfallback');

interface PromotionFallbackProps {
  translate: Translate;
  widgetType: string;
  params: WidgetProps['params'];
  linkText: string;
}

const PromotionFallback: React.FC<PromotionFallbackProps> = ({
  translate,
  widgetType,
  params,
  linkText,
}) => {
  if (widgetType === 'promotion') {
    return (
      <PromotionMain>
        <p className={styles.message}>{translate('promotionFallbackMessage')}</p>
      </PromotionMain>
    );
  }
  return <>{linkText || (params && params.model_name)}</>;
};

PromotionFallback.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: string | null,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default PromotionFallback;
