import type { WidgetProps } from '../../types/WidgetProps';

/**
 * * Re-fetch the data if the params have changed due to the change in widget type or battle
 * But don't send an extra request if only the product types have changed
 * and we already have data for the product type
 * This causes a large increase in API requests, which are pointless in reality as we need
 * the correct product type to apply the widget type component - HAWK-802
 */
export default (
  params: WidgetProps['params'],
  newParams: WidgetProps['params'],
  offerType: string,
): boolean => {
  const diff: string[] = [];
  Array.from(new Set(Object.keys(params).concat(Object.keys(newParams)))).forEach((key) => {
    if (newParams[key] !== params[key]) {
      if (key === 'filter_product_types') {
        const productTypes = (newParams[key] || '').split(',');
        if (productTypes.indexOf(offerType) === -1) {
          diff.push(key);
        }
      } else {
        diff.push(key);
      }
    }
  });
  return diff.length > 0;
};
