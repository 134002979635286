import type { APIData } from '../../types/APIData';
import type { BattleTest } from '../../types/Battle';
import type { BattleData } from '../../types/BattleData';
import type { BattleDealOverrides } from '../../types/BattleDealOverrides';
import type { WidgetProps } from '../../types/WidgetProps';
import { checkBattleCriteria, getBattleVariant } from '../setupBattle';

/**
 * Setup the data for the battle test - CSS, JS, deal overrides and widget/api features
 */
export default (
  test: BattleTest | null,
  props: WidgetProps,
  models?: Record<string, number>,
  data?: APIData | Record<string, never>,
): BattleData => {
  // Don't utils battle on server or in server tests to avoid breaking tests and widget flashing
  // Setup the test if the criteria matches (e.g. widget type)
  if (test && checkBattleCriteria(test.criteria, props, models, data)) {
    const variant = getBattleVariant(test, props);

    const dealOverrides: BattleDealOverrides[] = [];

    // Setup deal overrides
    if (variant && variant.deal_types && variant.deal_types.length > 0) {
      dealOverrides.push({
        overrides: variant.overrides,
        dealTypes: variant.deal_types,
        condition: variant.condition,
      });
    }

    return {
      battleFeatures: variant ? variant.features : null,
      battleDealOverrides: dealOverrides,
      test,
      variant,
    };
  }

  return {} as BattleData;
};
