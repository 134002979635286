import { WidgetId } from '../../types/Widget';

import checkParamsDifference from './checkParamsDifference';
import setupTabs from './setupTabs';
import type { ModelWidgetData } from './types';

/**
 * Update the feature props if the widget type was changed by the widgetTypeComponent or battle
 * Always refetch the data for a specs widget
 */
export const shouldReFetchData = (setupModelWidgetData: ModelWidgetData): ModelWidgetData => {
  const { options, model } = setupModelWidgetData;
  const { apiFeatures, widgetFeatures, localiser, props } = options;
  const { widget, params } = props;
  const { response, battleFeatures, widgetChanged, widgetMissing } = model;
  const { data, models, area } = response;

  if (widgetMissing) {
    return setupModelWidgetData;
  }

  if (widgetChanged) {
    widgetFeatures.setAttribute('widget', widget);
    apiFeatures.setAttribute('widget', widget);
    const newParams = apiFeatures.getFeatures('value', true);

    // Setup the tabs again if the widget was changed, to ensure we setup the tabs correctly
    const tabs = setupTabs({
      data,
      params: newParams,
      features: widgetFeatures.getFeatures('id'),
      widget,
      localiser,
      models,
      area,
    });

    const { tabConfigs, defaultTab } = tabs;

    return {
      options: {
        ...options,
        widgetFeatures,
        apiFeatures,
        props: {
          ...props,
          ...widgetFeatures.getFeatures('id'),
          params: {
            ...params,
            ...newParams,
          },
        },
      },
      model: {
        ...model,
        reFetchData: checkParamsDifference(params, newParams, data?.offer_type),
        tabConfigs,
        defaultTab,
      },
    };
  }
  if (battleFeatures) {
    const newParams = apiFeatures.getFeatures('value', true);

    return {
      options: {
        ...options,
        widgetFeatures,
        apiFeatures,
        props: {
          ...props,
          ...widgetFeatures.getFeatures('id'),
          params: {
            ...params,
            ...newParams,
          },
        },
      },
      model: {
        ...model,
        reFetchData: checkParamsDifference(params, newParams, data?.offer_type),
      },
    };
  }

  return {
    options,
    model: {
      ...model,
      reFetchData: widget.id === WidgetId.SPECS,
    },
  };
};
