import getUrlParameters from '../../../../utils/getUrlParameters';
import type { Deal } from '../../../types/Deal';

/**
 * Set hot_deal value for deal with most clicks_count if its above X threshold
 */
export const selectHotDeal = (data: Deal[]): Deal[] => {
  let mostClicks = 0;
  let mostClickedDealIndex: number | null = null;

  const { forceHotDeal } = getUrlParameters(['forceHotDeal']);

  data.map((deal, index) => {
    if (deal?.click_count > mostClicks) {
      mostClicks = deal.click_count;
      mostClickedDealIndex = index;
    }
    return deal;
  });

  const hotDealThreshold = 1000;
  if (mostClickedDealIndex !== null && mostClicks >= hotDealThreshold) {
    data[mostClickedDealIndex].hot_deal = mostClicks;
  }

  // To make easier testing
  if (forceHotDeal) {
    if (mostClickedDealIndex !== null && mostClicks >= Number(forceHotDeal)) {
      data[mostClickedDealIndex].hot_deal = mostClicks;
    }
  }

  return data;
};
