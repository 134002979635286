import { InitialiserResult } from '../../types/InitialiserResult';
import { ModelWidgetOptions } from '../../types/ModelWidgetOptions';

import { enrichModelWidgetWithAiredaleInfo } from './getAirdaleInfo';
import { getInitialDataModel } from './getInitialDataModel';
import { getModelWidget } from './getModelWidget';
import { sendApiRequest } from './sendApiRequest';
import { setupBattle } from './setupBattle';
import { setupTabBasedData } from './setupTabBasedData';
import { shouldReFetchData } from './shouldReFetchData';
import { updateWidgetConfig } from './updateWidgetConfig';

export default async (options: ModelWidgetOptions): Promise<InitialiserResult> => {
  const dataFromAPI = await sendApiRequest({
    options,
    model: getInitialDataModel(),
  });
  const updatedModelData = updateWidgetConfig(dataFromAPI);
  const dataWithBattleTest = setupBattle(updatedModelData);
  const dataWithChangedWidget = shouldReFetchData(dataWithBattleTest);
  const tabBasedData = await setupTabBasedData(dataWithChangedWidget);
  const airedaleInfoData = await enrichModelWidgetWithAiredaleInfo(tabBasedData);
  return getModelWidget(airedaleInfoData);
};
