import type { SeasonalResponse } from '../../../assets/types/Response';
import type { RequestType, getJSONFn } from '../../types/GetJSON';
import type { WidgetProps } from '../../types/WidgetProps';

interface SeasonalData {
  data: SeasonalResponse['link'];
  responseTime: number;
}

export default async (
  getJSON: getJSONFn<RequestType.SEASONAL_REQUEST>,
  endpoint: WidgetProps['endpoint'],
  params = {},
): Promise<SeasonalData> => {
  if (getJSON) {
    const result = await getJSON(endpoint, params);
    const { body, responseTime } = result;

    // Don't allow an empty response - i.e. bf.php returning data: []
    // Prevent widget from being rendered - widget should be considered missing
    if (body && body.link) {
      return {
        data: body.link,
        responseTime,
      };
    }
  }
  return {
    data: null,
    responseTime: 0,
  };
};
