import getWidget from '../../../utils/getWidget';

import getWidgetTypeComponent from './getWidgetTypeComponent';
import setupTabs from './setupTabs';
import type { ModelWidgetData } from './types';
import { isWidgetMissing } from './utils/isWidgetMissing';

export const updateWidgetConfig = (setupModelWidgetData: ModelWidgetData): ModelWidgetData => {
  const { options, model } = setupModelWidgetData;
  const { features, localiser, props } = options;
  const { params, widget } = props;
  const { response } = model;
  const { models, area, data } = response;

  const { tabConfigs, defaultTab } = setupTabs({
    data,
    params,
    features,
    widget,
    localiser,
    models,
    area,
  });
  const widgetMissing = isWidgetMissing(defaultTab, tabConfigs);

  if (widgetMissing) {
    return {
      options,
      model: {
        ...model,
        defaultTab,
        tabConfigs,
        widgetMissing,
      },
    };
  }

  const widgetTypeComponent = getWidgetTypeComponent(props, defaultTab);

  // Update the widget config if the widget type was changed by the widget type component
  if (widget.id !== widgetTypeComponent) {
    return {
      options: {
        ...options,
        props: {
          ...props,
          widgetTypeComponent,
          widget: getWidget(widgetTypeComponent),
        },
      },
      model: {
        ...model,
        widgetChanged: true,
        defaultTab,
        tabConfigs,
        widgetMissing,
      },
    };
  }

  return {
    options: {
      ...options,
      props: {
        ...props,
        widgetTypeComponent,
      },
    },
    model: {
      ...model,
      widgetChanged: false,
      defaultTab,
      tabConfigs,
      widgetMissing,
    },
  };
};
