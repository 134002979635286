import type { Promo } from '../../../../types/Deal';
import { WidgetProps } from '../../../../types/WidgetProps';
import productCostReact from '../../../../utils/productCostReact';
import getPercentageSaving from '../../../getPercentageSaving';

interface Props {
  showWasPrice: WidgetProps['showWasPrice'];
  percentageOffThreshold: WidgetProps['percentageOffThreshold'];
  localiser: WidgetProps['localiser'];
  deal: WidgetProps['dealData']['deals'][number];
}

export const addWasPricePromos = ({
  showWasPrice,
  percentageOffThreshold,
  localiser,
  deal,
}: Props): Promo[] | undefined => {
  if (['promoPercentage', 'allPercentage'].includes(showWasPrice)) {
    if (
      deal?.offer?.was_price &&
      !deal?.promos?.some((promo) => promo.type === 'was-price-percentage')
    ) {
      const percentage = getPercentageSaving(deal);
      if (percentage && Number(percentage) >= percentageOffThreshold) {
        const value = `${percentage}% OFF`;

        return [
          {
            type: 'was-price-percentage',
            value,
            display_value: value,
          },
        ].concat(deal?.promos || []);
      }
      return deal?.promos || [];
    }
  } else if (['promoSaving', 'allSaving'].includes(showWasPrice)) {
    if (
      deal?.offer?.was_price &&
      !deal?.promos?.some((promo) => promo.type === 'was-price-light')
    ) {
      const saving = productCostReact({
        price: (Number(deal.offer.was_price) - Number(deal.offer.price)).toFixed(2),
        currencyIso: deal.offer.currency_iso,
        language: localiser.language,
      });
      const value = localiser.translate('priceDiscount', [saving]);

      return (deal?.promos || []).concat([
        {
          type: 'was-price-light',
          value,
          display_value: value,
        },
      ]);
    }
  }
  return deal?.promos;
};
