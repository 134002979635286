import type { WidgetProps } from './WidgetProps';

export enum WidgetWrapper {
  SPAN = 'span',
  DIV = 'div',
}

export type GetInitialiserProps = Promise<{
  props: WidgetProps;
  apiCall?: string;
  type: string;
  widgetWrapper: WidgetWrapper;
}>;
