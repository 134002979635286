import { v4 as uuidv4 } from 'uuid';

import { getHawk } from '../../utils/global';

// Generate an id for a widget - this is passed through to the click event
export default (): string => {
  // Allow forcing the id for Cypress tests
  const { widgetId } = getHawk();
  if (widgetId) {
    return widgetId;
  }

  return uuidv4();
};
