import { WidgetId } from '../../../core/types/Widget';
import { enumFromStringValue } from '../../../utils/enumFromStringValue';

const getWidgetType = (widgetType?: string): WidgetId | null => {
  if (widgetType) {
    return enumFromStringValue(WidgetId, widgetType) ?? null;
  }

  return null;
};

export default getWidgetType;
