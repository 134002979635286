// Add the (filtered) urlParams to the parameters array
export const getFilteredUrlParams = (articleUrl: string): string[] | undefined => {
  // Exception for iframes to prevent us from using an incorrect site name - HAWK-1999
  if ((articleUrl || '').indexOf('iframe.html') >= 0) {
    return [];
  }
  if (process.env.RESTRICT_URL_PARAMS) {
    return [
      'area',
      'modelId',
      'modelName',
      'now',
      'battle',
      'rand',
      'seenMatchId',
      'visibleMatchId',
    ];
  }
  return undefined;
};
