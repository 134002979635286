import { useHawkWidgetContext } from '../../components/GenericComponents/HawkWidget/HawkWidgetProviderContext';
import type { Filter } from '../../types/Filter';

import getFilterConfigs from './getFilterConfigs';

export default function useFilterConfigs(filters: Filter[], type: string): Filter[] {
  const { blacklistedFilters } = useHawkWidgetContext();
  const blacklistedFiltersArray = blacklistedFilters.split(',');
  const newFilters = filters.filter((filter) => {
    return !blacklistedFiltersArray.includes(filter.title);
  });

  return getFilterConfigs(newFilters, type);
}
