import type WidgetFeaturesIds from '../../core/modules/features/types/WidgetFeaturesIds';
import { WidgetId } from '../../core/types/Widget';
import type { Widget } from '../../core/types/Widget';
import type { WidgetInitialiserOptions } from '../../core/types/WidgetInitialiser';

import sanitizeString from './sanitizeString';

export const getSkip = (
  props: Partial<WidgetFeaturesIds>,
  options: Partial<WidgetInitialiserOptions>,
  widget: Widget,
): boolean => {
  const { editorialOnPage, keywords, defaultParams } = options;

  const { skip, excludeFrom, hiddenDealWidget } = props;

  let skipWidget = Boolean(
    (skip || '').split(',').find((s) => {
      switch (s) {
        case 'disabled':
          // If skip is disabled, the widget should not be rendered regardless fo anything else
          // (i.e. van-hawk-none)
          return true;
        case 'editorial':
          // Only render if there are no editorial widgets unless they are type 'link' or 'inbody'
          return editorialOnPage;
        case 'phones':
          // Added for inbody widget
          return defaultParams?.articleCategory === 'phones';
        case 'retail':
          // Added for inbody widget
          return defaultParams?.articleCategory === 'retail';
        case 'dealsy':
          // Added for seasonal widget - skip if a substring match for any of the below tags
          // are found or the article type is deals
          return keywords?.find((k) => {
            const tags = ['amazonprimeday', 'blackfriday', 'sensitive'];

            return (
              tags.find((t) => sanitizeString(k).indexOf(t) >= 0) ||
              defaultParams?.articleType === 'deals'
            );
          });
        default:
          // Always render
          return false;
      }
    }),
  );

  if (widget.id === WidgetId.DEAL && excludeFrom && hiddenDealWidget) {
    skipWidget = true;
  }

  return skipWidget;
};
