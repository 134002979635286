import getUrlParameters from '../../../utils/getUrlParameters';
import type { Tab } from '../../types/Tab';
import { WidgetId } from '../../types/Widget';
import type { WidgetProps } from '../../types/WidgetProps';

export default (props: WidgetProps, defaultTab: Tab | null): WidgetId => {
  const { params, site, platform, editorial, widget, name } = props;
  /**
   * Use the provided widget type unless:
   * - the widget type was forced in the url for debugging
   * - the site is Techradar, the article is phones, it's a review widget
   * - the platform is responsive, the article is a retail review, it's a review widget
   * - there are game platform tabs and it's a review widget
   * */
  const { widgetType: urlWidgetType } = getUrlParameters(['widgetType']);
  if (String(process.env.RESTRICT_URL_PARAMS) === '0' && urlWidgetType) {
    return urlWidgetType as WidgetId;
  }

  if (
    site.id === 'trd' &&
    widget.id === WidgetId.REVIEW &&
    defaultTab &&
    defaultTab.value === 'contracts'
  ) {
    return WidgetId.POCKET;
  }
  if (
    platform === 'responsive' &&
    params.article_type === 'review' &&
    defaultTab &&
    defaultTab.value === 'deals' &&
    widget.id === WidgetId.REVIEW &&
    !editorial &&
    name !== 'BOT widget'
  ) {
    return WidgetId.MERCHANT_LINK;
  }
  if (
    defaultTab &&
    defaultTab.type === 'label_text_game_platform' &&
    widget.id === WidgetId.REVIEW
  ) {
    return WidgetId.TABS;
  }
  if (
    params.article_type === 'buying_guide' &&
    widget.id === WidgetId.MERCHANT_LINK &&
    defaultTab &&
    defaultTab.value === 'subscriptions'
  ) {
    return WidgetId.PRICE;
  }

  return widget.id;
};
