import type { Widget } from '../../../core/types/Widget';
import getWidget from '../../../utils/getWidget';

import getWidgetType from './getWidgetType';

/**
 * For backwards compatibility ensure we use the display type to get the widget config
 * This is to handle old placeholders which request a display type which is now a widget
 * type & could have support for different product types
 */
export const getWidgetFromDisplayType = (displayType?: string, widgetType?: string): Widget => {
  if (displayType) {
    const displayTypeWidget = getWidget(getWidgetType(displayType));
    if (displayTypeWidget && displayTypeWidget.id) {
      return displayTypeWidget;
    }
  }

  return getWidget(getWidgetType(widgetType));
};
