import type { WidgetProps } from '../../types/WidgetProps';

import type { ModelData } from './types';

export const getArea = (props: WidgetProps, response: ModelData): string => {
  const { territory } = props;
  const { area: modelArea } = response;
  // get the area from Api response
  // if not API call made or area missing, use site territory from Vanilla Site Config
  // if no territory available default to GB
  if (modelArea) {
    return String(modelArea);
  }
  if (territory) {
    return String(territory);
  }
  return 'GB';
};
