import { Filter, FilterValue } from '../../types/Filter';
import ApiFeaturesValues from '../features/types/ApiFeaturesValues';

import getWhitelist from './getWhitelist';

export default (
  filter: Filter,
  params: Partial<ApiFeaturesValues> | Record<string, never>,
): FilterValue[] => {
  const whitelist = getWhitelist(filter, params);
  const values = filter && filter.values ? filter.values : [];
  const order = whitelist.length > 0 ? whitelist : values.map((v) => String(v.value));
  const result: FilterValue[] = [];
  order.forEach((orderValue: string) => {
    const value = values.find(
      (v: FilterValue) => String(v.value).toLowerCase() === orderValue.toLowerCase(),
    );
    if (value) {
      result.push(value);
    }
  });

  return result;
};
