import type { APIData } from '../../types/APIData';
import type { Deal } from '../../types/Deal';

export default (bundleData: APIData, nonBundleData: APIData): Deal[] => {
  const nonBundleOffersNoBuySimilar = nonBundleData.offers.filter((d) => d.product_type !== 200);

  if (nonBundleOffersNoBuySimilar.length === 0 && bundleData.offers.length > 0) {
    // There are bundle & non bundle deals, but the only non bundle deals are view similars
    // So just use the bundle deals
    return bundleData.offers;
  }
  if (nonBundleData.offers.length > 0 && bundleData.offers.length > 0) {
    /* There are bundle & non bundle deals, so use the non bundle deal with
    the highest score & all the standard deals
    Ensure we check that the offers aren't the same just in case */
    const priorityNonBundleOffers = nonBundleData.offers
      .filter((o) => !bundleData.offers.find((offer) => offer.match_id === o.match_id))
      .sort((a, b) => (b.score < a.score ? 1 : 0));

    if (priorityNonBundleOffers[0]) {
      return [priorityNonBundleOffers[0], ...bundleData.offers];
    }
    return bundleData.offers;
  }
  if (nonBundleData.offers.length > 0 && bundleData.offers.length === 0) {
    // There are non bundle deals, but no bundle deals. So just use all the non bundle deals
    return nonBundleData.offers;
  }

  // If there are only bundle deals, just use the bundle deals
  return bundleData.offers;
};
