import React from 'react';

import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import styles from './styles/promotion-main.css';

interface IPromotionMainProps {
  children?: React.ReactNode;
}

const PromotionMain: React.FC<IPromotionMainProps> = ({ children }) => (
  <Consumer>
    {(value): JSX.Element => {
      const { editorial } = value;

      return (
        <div className={styles.container} data-editorial={editorial ? 1 : 0}>
          {children}
        </div>
      );
    }}
  </Consumer>
);

export default PromotionMain;
