import type Localiser from '../../core/modules/Localiser';
import { storeWidgetData } from '../../core/modules/storeWidgetData';

export default (
  territory: string,
  localiser: Localiser,
  articleType?: string,
  articleCategory?: string,
): void => {
  // Store data about the article and setup for Hawk X
  storeWidgetData(
    {
      articleType,
      articleCategory,
      territory,
      language: localiser.articleLanguage,
      hawkLanguage: localiser.language,
    },
    'article',
  );
};
