import type { APIData } from '../../types/APIData';
import { storeWidgetData } from '../storeWidgetData';

export default (data: APIData, widgetId: string, battleId: string | null = null): void => {
  // Store widget data for Hawk X
  storeWidgetData(
    {
      data,
      battleId,
    },
    `${widgetId}`,
  );
};
