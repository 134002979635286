import type { DataModel, ModelData } from './types';

export const getInitialDataModel = (): DataModel => {
  return {
    status: '',
    response: {} as ModelData,
    responseTime: 0,
    battleDealOverrides: [],
    battleFeatures: null,
    widgetMissing: false,
    widgetChanged: false,
    reFetchData: false,
    tabConfigs: [],
    defaultTab: null,
    tabData: {},
    tabParams: {},
    airdaleData: {},
  };
};
