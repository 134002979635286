import type { Tab } from '../../types/Tab';
import type { TabData } from '../../types/TabData';

import getBundleOffers from './getBundleOffers';

export default (
  data: TabData,
  tabConfigs: Tab[],
  defaultTab: Tab | null,
): {
  tabConfigs: Tab[];
  data: TabData;
} => {
  // Prepend the offers from the nonBundle tab for the bundle widget
  if (defaultTab && tabConfigs && tabConfigs.find((t) => t.value === 'bundle')) {
    const bundleData = data['bundle'];
    const nonBundleData = data[defaultTab.value];

    if (nonBundleData && nonBundleData.offers && bundleData && bundleData.offers) {
      data[defaultTab.value] = {
        ...data[defaultTab.value],
        offers: getBundleOffers(bundleData, nonBundleData),
      };

      /* Remove the separate nonBundle data - we don't want this to
      be displayed as a tab in the widget */
      delete data.bundle;
      tabConfigs = tabConfigs.filter((t) => t.value !== 'bundle');
    }
  }

  return {
    tabConfigs,
    data,
  };
};
