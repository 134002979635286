import { APIFeatures, WidgetFeatures } from '../../core/modules/features';
import type { InitialiserResult } from '../../core/types/InitialiserResult';
import { WidgetId } from '../../core/types/Widget';
import type { WidgetInitialiserOptions } from '../../core/types/WidgetInitialiser';
import type { WidgetProps } from '../../core/types/WidgetProps';
import { getFilteredUrlParams } from '../../utils/getFilteredUrlParams';
import getUrlParameters from '../../utils/getUrlParameters';
import { getSkip } from '../getSkip';

import { getWidgetFromDisplayType } from './getWidgetFromDisplayType';
import getWidgetId from './getWidgetId';
import storeArticleData from './storeArticleData';

/**
 * Get the widget initialiser
 */
export const getWidgetInitialiser = async (
  options: WidgetInitialiserOptions,
): Promise<InitialiserResult> => {
  options = options || {};
  const {
    editorial,
    placeholder,
    defaultParams,
    endpoint,
    index,
    site,
    platform,
    territory,
    localiser,
    url,
    keywords,
    getJSON,
    articleId,
    articleName,
    articleUrl,
    getInitialiser,
    origin,
    dataLinkMerchant,
  } = options;
  let { attributes } = options;

  const whitelist = getFilteredUrlParams(articleUrl);
  const urlParams = getUrlParameters(whitelist);
  const widget = getWidgetFromDisplayType(attributes.displayType, attributes.widgetType);

  attributes = {
    ...(defaultParams as DOMStringMap), // cant assign our types into DOMStringMap
    ...attributes,
    ...urlParams,
  };

  const apiFeatures = new APIFeatures(
    attributes,
    widget,
    editorial,
    platform,
    localiser,
    site,
    territory,
    keywords,
    origin,
  );
  const widgetFeatures = new WidgetFeatures(
    attributes,
    widget,
    editorial,
    platform,
    localiser,
    site,
    territory,
    keywords,
  );
  /* Get the widget options - params that only affect the widget & not the API data
  Get the whole object & destructure separately so we can later assign all the
  features without calling getWidgetFeatures again */
  const features = widgetFeatures.getFeatures('id');
  const params = {
    ...apiFeatures.getFeatures('value'),
    ...apiFeatures.getLabels(),
  };

  const skipWidget = getSkip(features, options, widget);

  // Get data for all tabs if bundle_models feature is enabled
  const widgetEndpoint = widget.endpoint === '' ? '' : `${endpoint}/${widget.endpoint}`;
  const props = {
    widget,
    endpoint,
    widgetEndpoint,
    editorial,
    placeholder,
    site,
    platform,
    territory,
    localiser,
    language: localiser.language,
    translate: localiser.translate.bind(localiser),
    url,
    index,
    articleId,
    articleName,
    articleUrl,
    keywords,
    ...features,
    params,
    widgetTypeComponent: widget.id,
    getJSON,
    widgetId: getWidgetId(),
    dataLinkMerchant,
  } as unknown as WidgetProps;

  if (widget && widget.id && !skipWidget) {
    storeArticleData(territory, localiser, params.article_type, params.article_category);

    const setup = await getInitialiser(widget);

    if (typeof setup !== 'function') {
      return {
        props,
        type: 'skipped',
      };
    }

    const result = await setup({
      props,
      apiFeatures,
      widgetFeatures,
      localiser,
      features,
    });

    const widgetTypeComponent =
      result.type === 'missing' && result.props.widgetTypeComponent === WidgetId.PROMOTION
        ? WidgetId.PROMOTION_FALLBACK
        : result.props.widgetTypeComponent;

    const type = result.apiCall === 'failure' ? 'preserved' : result.type;

    return {
      ...result,
      type,
      props: {
        ...result.props,
        widgetTypeComponent,
      },
    };
  }
  // Reject when the widget is not recognised or skipped
  return {
    props,
    type: 'skipped',
  };
};
