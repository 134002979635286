import type { RequestType, getJSONFn } from '../../types/GetJSON';

import type { ModelData } from './types';

export default async (
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  endpoint: string,
  params = {},
): Promise<ModelData> => {
  if (getJSON) {
    const result = await getJSON(endpoint, params);
    const { body, responseTime } = result;
    if (body) {
      return {
        data: body.widget && body.widget.data,
        battle: body.battle,
        area: body.area,
        models: body.models,
        responseTime,
      };
    }
  }
  return {
    data: {},
    battle: null,
    area: null,
    models: {},
    responseTime: 0,
  };
};
