import type { APIData } from '../../types/APIData';
import type { Tab } from '../../types/Tab';
import type { WidgetProps } from '../../types/WidgetProps';
import { getCookie } from '../cookie';
import type WidgetFeaturesIds from '../features/types/WidgetFeaturesIds';
import { getTabConfigs } from '../getTabConfigs';
import type Localiser from '../Localiser';

interface SetupTabsProps {
  data: APIData | Record<string, never>;
  params: WidgetProps['params'];
  features: Partial<WidgetFeaturesIds>;
  widget: WidgetProps['widget'];
  localiser: Localiser;
  models: Record<string, number>;
  area: string | null;
}

export default ({
  data,
  params,
  features,
  widget,
  localiser,
  models,
  area,
}: SetupTabsProps): {
  tabConfigs: Tab[];
  defaultTab: Tab | null;
} => {
  const tabConfigs = getTabConfigs({
    data,
    params,
    features,
    area,
    widget,
    localiser,
    models,
  });

  return {
    tabConfigs,
    defaultTab: ((): Tab | null => {
      const cookieTab = getCookie('hawk-review-widget-tab');

      // Only use the cookie tab for game platform tabs
      if (
        cookieTab &&
        tabConfigs.find((tab) => tab.value === cookieTab && tab.type === 'gamePlatform')
      ) {
        /* The tab might not be found - e.g. saved as deals, but the next
        review widget has game platform tabs (no deals tab) */
        const tab = (tabConfigs || []).find((tab) => tab.value === cookieTab);
        if (tab) {
          return tab;
        }
      }
      if (tabConfigs && tabConfigs[0]) {
        return tabConfigs[0];
      }
      return null;
    })(),
  };
};
