import type { APIData } from '../../types/APIData';
import type { Criterion } from '../../types/Battle';
import type { WidgetId } from '../../types/Widget';
import type { WidgetProps } from '../../types/WidgetProps';

export default (
  criteria: Criterion[],
  props: WidgetProps,
  models: Record<string, number> | undefined = undefined,
  data: APIData | Record<string, never> | undefined = undefined,
): boolean => {
  if (criteria.length === 0) {
    return true;
  }
  // Return true if any value for each criterion is matched
  // e.g. the widget type is in one of the specified widget types
  // The modelName attribute is ignored as we use the model id instead (easier lookups)
  return criteria
    .filter(({ attribute }) => attribute !== 'modelName')
    .every(({ attribute, values }) => {
      return (values || []).some((value) => {
        if (attribute === 'widgetType') {
          return (value as WidgetId) === props.widget.id;
        }
        if (attribute === 'editorial') {
          return Boolean(parseInt(value, 10)) === props[attribute];
        }
        if (attribute === 'modelId' && models) {
          // Exact model match
          if (Object.values(models).find((id) => String(id) === value)) {
            return true;
          }
          // Requested model is a child model
          return Boolean(
            Object.keys(models).find((modelName: string) => {
              const modelId = models[modelName];
              const parents =
                data &&
                data.model_info &&
                data.model_info[modelId] &&
                data.model_info[modelId].parents
                  ? data.model_info[modelId].parents
                  : [];
              return parents.find((p) => String(p.id) === value);
            }),
          );
        }
        // This shouldn't match any criteria, so consider it valid if it does
        // (e.g. new criteria that isn't supported yet)
        return true;
      });
    });
};
