import getUrlParameters from '../../../../utils/getUrlParameters';
import { getHawk } from '../../../../utils/global';
import type { APIData } from '../../../types/APIData';
import type { BattleDealOverrides } from '../../../types/BattleDealOverrides';
import type { Deal, Promo } from '../../../types/Deal';
import type { Tab } from '../../../types/Tab';
import type { Widget } from '../../../types/Widget';
import type { WidgetProps } from '../../../types/WidgetProps';
import WidgetFeaturesIds from '../../features/types/WidgetFeaturesIds';
import type Localiser from '../../Localiser';
import type { Site } from '../../sites/types/Site';

import { addCtaToDeal } from './addCtaToDeal';
import { addFallbackOffer } from './addFallbackOffer';
import { getCustomTrackedOffer } from './getCustomTrackedOffer';
import { getDealOverrideValidity } from './getDealOverrides';
import { getOverridedDeal } from './getOverridedDeal';
import { getPromos } from './getPromos';
import { selectHotDeal } from './selectHotDeal';

const getOverrides = (overrides: BattleDealOverrides[]): BattleDealOverrides[] => {
  // Support overrides in window.hawk so we can show the CTA in the Battle variant preview (console)
  const { overrides: hawkOverrides } = getHawk();
  if (hawkOverrides && hawkOverrides.length > 0) {
    return hawkOverrides;
  }

  return overrides || [];
};

interface GetFormattedOffersProps {
  dataLinkMerchant: string | null;
  data: APIData;
  localiser: Localiser;
  site: Site;
  area: string | null;
  articleName: string;
  articleUrl: string;
  params: WidgetProps['params'];
  widget: Widget;
  defaultTab?: Tab | null;
  overrides: BattleDealOverrides[];
}

export type GetFormattedOffersOptions = GetFormattedOffersProps & Partial<WidgetFeaturesIds>;

type FormattedOffer = Deal & { hot_deal?: string } & Deal & Promo[];

export const getFormattedOffers = async (
  options: GetFormattedOffersOptions,
): Promise<FormattedOffer[]> => {
  const {
    dataLinkMerchant,
    showPrice = '',
    showWasPrice = '',
    percentageOffThreshold,
    showPromos,
    localiser,
    site,
    area,
    articleName,
    articleUrl,
    link,
    linkText = '',
    params,
    widget,
    overrides,
    customPromo,
    excludeFrom,
    showFallback,
    customPromos,
    preserveEditorialLink,
    defaultTab,
    editorialCurrency = '',
    editorialPrice = '',
    editorialWasPrice = '',
  } = options;
  let { data } = options;
  data = data || {};
  // Get needed variables
  const { customTrackingId: forcedCustomTrackingId } = getHawk();

  /* Setup deals etc. based on the deal type - use the first deal
  type that is recognised and deals are found for
  Overwrite the deal object with an override if it has been
  utils & applies to this deal */
  const overrideConfig = getOverrides(overrides).find((config) => {
    return config.dealTypes && config.dealTypes.indexOf(data.offer_type) >= 0;
  });
  const modelName = params && params.model_name;

  const localisedLinkText = localiser.translate(linkText);

  const offers = addFallbackOffer({
    dataLinkMerchant,
    offers: data.offers || [],
    pageSize: defaultTab ? defaultTab.pageSize : 4,
    preserveEditorialLink,
    modelName,
    widgetId: widget.id,
    localiser,
    link,
    localisedLinkText,
    editorialCurrency,
    editorialPrice,
    editorialWasPrice,
  });

  const offers_with_hotdeal = selectHotDeal(offers);

  return Promise.all(
    offers_with_hotdeal.map(async (deal) => {
      // Add CTA to deal.offer
      const ctaDeal = addCtaToDeal({
        localiser,
        localisedLinkText,
        showFallback,
        showPrice,
        modelName,
        widget,
        deal,
      });

      // Check that the override is valid for this offer
      const shouldOverrideDeal =
        overrideConfig && getDealOverrideValidity(ctaDeal, overrideConfig.condition);
      /* Override the deal object based on the override so we can
    support battle deal overrides (e.g. CTA) */
      const overridedDeal = shouldOverrideDeal
        ? getOverridedDeal(overrideConfig.overrides, { ...ctaDeal })
        : ctaDeal;

      // Allow overriding the hot deal value - so we can test it without needing to hardcode it
      const { hotDeal } = getUrlParameters(['hotDeal']);
      const hotDealProps = !overrideConfig && hotDeal ? { hot_deal: hotDeal } : {};

      // Get promos base on specified rules
      const promos = getPromos({
        percentageOffThreshold,
        showWasPrice,
        localiser,
        deal: overridedDeal,
        customPromo,
        customPromos,
        excludeFrom,
        showPromos,
        widget,
      });

      // Rewrite the custom tracking of the selected link fields
      const customTrackedOffer = await getCustomTrackedOffer({
        deal: overridedDeal,
        site,
        articleName,
        articleUrl,
        params,
        area,
        link,
        linkText: localisedLinkText,
        widget,
        forcedCustomTrackingId,
        /**
         * We do not want to generate custom tracking id in browser during first render
         * in browser we generate custom tracking id on click
         * */
        rawLink: null,
      });

      return {
        ...overridedDeal,
        ...hotDealProps,
        ...customTrackedOffer,
        ...(promos ? { promos } : {}),
      } as FormattedOffer;
    }),
  );
};
