import type { Deal } from '../../../../types/Deal';

import { getFallbackOffer } from './getFallbackOffer';

export const addFallbackOffer = ({
  dataLinkMerchant,
  link,
  localisedLinkText,
  offers,
  preserveEditorialLink,
  pageSize,
  localiser,
  modelName,
  widgetId,
  editorialCurrency,
  editorialPrice,
  editorialWasPrice,
}): Deal[] => {
  // We don't want to add the fallback twice "!addFallback"
  // We don't want to add the fallback if no editorial link and linkText is provided
  // if there is view similar make sure that adding the fallback doen't go over the pagesize
  const addFallback = Boolean(
    link &&
      localisedLinkText &&
      (offers.length === 0 ||
        (offers.length === offers.filter((o) => o.product_type === 200).length &&
          offers.length < pageSize)),
  );
  const hasFallback = Boolean(link && localisedLinkText);

  // We don't want to add the fallback twice
  if (preserveEditorialLink && offers.length !== 0 && !addFallback && hasFallback) {
    const fallbackOffer = getFallbackOffer({
      dataLinkMerchant,
      link,
      linkText: localisedLinkText,
      localiser,
      modelName,
      widgetId,
      editorialCurrency,
      editorialPrice,
      editorialWasPrice,
    });

    if (offers.length === pageSize) {
      const offersArray = [fallbackOffer, ...offers];
      offersArray.pop();
      return offersArray;
    }

    return [fallbackOffer, ...offers];
  }
  if (addFallback) {
    return [
      ...offers,
      getFallbackOffer({
        dataLinkMerchant,
        link,
        linkText: localisedLinkText,
        localiser,
        modelName,
        widgetId,
        editorialCurrency,
        editorialPrice,
        editorialWasPrice,
      }),
    ];
  }

  return offers;
};
