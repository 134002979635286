import type { DealData } from '../../types/DealData';
import type WidgetFeaturesIds from '../features/types/WidgetFeaturesIds';

export default (
  apiCall: string,
  dealData: DealData | null,
  { showNoDeals }: Partial<WidgetFeaturesIds>,
): boolean => {
  const validData = (dealData && dealData.deals && dealData.deals.length > 0) || showNoDeals;

  if (apiCall === 'success' && validData) {
    // Resolve with props & placeholder
    return true;
  }
  if (apiCall === 'success' && !validData) {
    /*
         Missing event if:
         - api call complete
         and
         - data is not valid
         and
         - not rendering empty
         */
    return false;
  }
  if (apiCall && ['failure', 'skipped'].indexOf(apiCall) >= 0) {
    // The api call failed, possibly due to XHR requests being ad blocked
    return false;
  }

  return false;
};
