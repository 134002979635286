import type { Variant } from '../../../types/Battle';
import type { Deal, OverrideDeal } from '../../../types/Deal';

export const getDealOverrides = (
  deal: Deal,
  overrides: OverrideDeal = {} as OverrideDeal,
): Deal => {
  const convertedOverrides = {} as Deal;
  Object.keys(overrides).forEach((key) => {
    // Replace placeholder text with values from the deal object (e.g. {{merchant.name}}
    // should change to 'Amazon')
    const value = overrides[key];
    if (typeof value === 'string') {
      const placeholderIndex = value.indexOf('{{');
      if (placeholderIndex >= 0) {
        const placeholderText = value.substring(placeholderIndex).replace(/([{}]*)/g, '');
        let result = { ...deal };
        placeholderText.split('.').forEach((str) => {
          result = result[str];
        });

        convertedOverrides[key] = `${overrides[key].substring(0, placeholderIndex)}${result}`;
      } else {
        convertedOverrides[key] = value;
      }
    } else {
      convertedOverrides[key] = value;
    }
  });

  return convertedOverrides;
};

// Can be any because we can get any prop from Deal
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPropBasedOnConditionFields = (prop: any, propPath: string[]): any => {
  const propChild = propPath.shift();
  return propChild ? getPropBasedOnConditionFields(prop[propChild], propPath) : prop;
};

export const getDealOverrideValidity = (deal: Deal, condition: Variant['condition']): boolean => {
  let overrideDeal = true;
  if (condition) {
    const { value, fields } = condition;

    const result = getPropBasedOnConditionFields(deal, fields);
    /* Override the deal if the value matches the deal value
    (e.g. fields = ['offer', 'buy_similar'] & value = true) */
    overrideDeal = result === value;
  }

  return overrideDeal;
};
