import type { Promo } from '../../../../types/Deal';

import { addCustomPromos } from './addCustomPromos';
import { addWasPricePromos } from './addWasPricePromos';
import { getFilteredPromos } from './getFilteredPromos';

export const getPromos = ({
  showWasPrice,
  percentageOffThreshold,
  localiser,
  deal,
  customPromo,
  customPromos,
  excludeFrom,
  showPromos,
  widget,
}): Promo[] => {
  /* Add reduced price promo if there is a was price & the promo
  feature or all was price features are enabled */
  const wasPricePromos = addWasPricePromos({
    percentageOffThreshold,
    showWasPrice,
    localiser,
    deal,
  });

  // Add the custom promos to promos for the deal widget if set in the CMS by the editor
  const customWasPricePromos = addCustomPromos({
    localiser,
    customPromo,
    customPromos,
    promos: wasPricePromos,
  });

  // Do not display promos in certain use cases
  return getFilteredPromos({
    localiser,
    excludeFrom,
    promos: customWasPricePromos,
    showPromos,
    widget,
  });
};
