import getUrlParameters from '../../../utils/getUrlParameters';
import type { Variant } from '../../types/Battle';
import type { WidgetProps } from '../../types/WidgetProps';

/**
 * Return the variant number
 */
export default (id: number, props: WidgetProps, variants: Variant[]): number => {
  // Force a variant based on the url
  const urlParams = getUrlParameters(['battle']);
  const paramUrl = urlParams && urlParams.battle ? urlParams.battle : '';
  // Flexi battle parameter
  const paramFlexi = props && props.params && props.params.battle ? props.params.battle : '';

  // If no forced url battle id is found checks for the flexi battle id
  const paramBattle = paramUrl || paramFlexi;

  const versionFormatRegex = /\d*-v\d*/;
  // battle parameter in '${battleId}-v${arrayIndex}' format
  if (versionFormatRegex.test(paramBattle)) {
    const match = paramBattle.match(new RegExp(`^${id}-v(\\d)`));
    if (match && match && match[1]) {
      return parseInt(match[1], 10);
    }
  }

  const idFormatRegex = /\d*-\d*/;
  // battle parameter in '${battleId}-${variantId}' format
  if (idFormatRegex.test(paramBattle)) {
    const variantId = Number(paramBattle.split('-')[1]);

    return variants.findIndex((variant) => variant.id === variantId);
  }

  return 0;
};
