import type { BattleTest, Variant } from '../../types/Battle';
import type { WidgetProps } from '../../types/WidgetProps';

import getVariantNumber from './getVariantNumber';

export default (test: BattleTest, props: WidgetProps): Variant | null => {
  if (test && test.variants && test.variants.length > 0) {
    // Use variant number if it was setup
    // i.e. preserve the variant number used from server side service
    const { variants } = test;
    const getVariantNumberResult = getVariantNumber(test.id, props, variants);
    const variantNumber = typeof getVariantNumberResult === 'number' ? getVariantNumberResult : 0;
    const variant = variants[variantNumber];

    // Check that the variant was found
    // e.g. it won't be if using the battle parameter and using too large a variant number
    if (variant) {
      return {
        ...variant,
        variantNumber,
      };
    }
    return null;
  }
  return null;
};
